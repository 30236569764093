export const getReadableVideoDurationFromSeconds = (seconds?: string | number): string => {
  if (seconds === 0) return '00:00'

  if (!seconds) return '00:00'
  let numberedSeconds = Math.floor(parseFloat(seconds.toString()))
  if (!numberedSeconds) return '00:00'

  const minutesDifference = Math.floor(numberedSeconds / 60)
  numberedSeconds -= minutesDifference * 60
  const secondsDifference = Math.floor(numberedSeconds)
  numberedSeconds -= secondsDifference

  const minutesString = minutesDifference.toString().padStart(2, '0')
  const secondsString = secondsDifference.toString().padStart(2, '0')

  return `${minutesString}:${secondsString}`
}

export const getReadableDurationFromSeconds = (seconds?: string): string => {
  if (!seconds) return 'N/A'

  let numberedSeconds = parseFloat(seconds)
  if (!numberedSeconds) return 'N/A'

  const hoursDifference = Math.floor(numberedSeconds / 60 / 60)
  numberedSeconds -= hoursDifference * 60 * 60
  const minutesDifference = Math.floor(numberedSeconds / 60)
  numberedSeconds -= minutesDifference * 60
  const secondsDifference = Math.floor(numberedSeconds)
  numberedSeconds -= secondsDifference

  const hoursString = hoursDifference.toString().padStart(2, '0')
  const minutesString = minutesDifference.toString().padStart(2, '0')
  const secondsString = secondsDifference.toString().padStart(2, '0')

  return `${hoursString}h ${minutesString}m ${secondsString}s`
}
